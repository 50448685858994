<template lang="pug">
.w-8.absolute.centred.z-30.bg-white.shadow.pr-4.pl-4.pt-5.pb-6.cursor-move(
  v-dragged='onDragged',
  style='width: 300px'
)
  .flex.w-full.justify-between.mb-2
    .w-full
      span.text-lightblue.font-semibold(
        style='width: 80px; display: inline-block'
      ) Объект :
      span.text-darkblue.font-semibold(
        style='word-break: break-word !important'
      ) {{ row.unit_name }}
    .flex
      i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold.ml-3(
        @click='$emit("close", index)'
      ) 
  .flex.w-full
    span.text-lightblue.font-semibold(style='width: 80px') Дата :
    span.text-darkblue.font-semibold {{ row.date_and_time }}
  .w-full.mt-4
    img(style='border-radius: 7px', :src='basicUrl + row._photo_url')
</template>

<script>
export default {
  props: ['row', 'index'],
  components: {},

  data() {
    return {
      basicUrl: '',
    }
  },
  created() {
    this.basicUrl = this.basicUrl = document.location.href + '/api_v1/'
    console.log('from component', this.row)
  },
  methods: {
    onDragged({
      el,
      deltaX,
      deltaY,
      offsetX,
      offsetY,
      clientX,
      clientY,
      first,
      last,
    }) {
      if (first) {
        this.isDragging = true
        return
      }
      if (last) {
        this.isDragging = false
        return
      }
      var l = +window.getComputedStyle(el)['left'].slice(0, -2) || 0
      var t = +window.getComputedStyle(el)['top'].slice(0, -2) || 0
      el.style.left = l + deltaX + 'px'
      el.style.top = t + deltaY + 'px'
    },
  },
}
</script>

<style lang="stylus">
.centred {
  position: fixed;
  top: 40%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
</style>
